@import url('https://fonts.googleapis.com/css?family=Titillium+Web');

.App {
  text-align: center;
  height: 98% !important;
  overflow: auto !important;
  .background{
    border-radius: 50%;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 15vh auto 0 auto;
  }
  .description{
    margin: 26px 3vw 0 3vw;
    height: auto;
    color: #fff;
    font-size: 1.4vmax;
    font-family: 'Titillium Web', cursive;
    text-shadow: 1px 1px 4px black;
  }
}


@media (max-width:1000px){
  .App {

    .background{
      margin: 3vh auto 0 auto;
    }
    .description{
      font-size: 2vmax;
    }
  }
}