$dotsCount: 44;

@mixin dots($count) {
    $text-shadow: ();
    @for $i from 0 through $count {
      $text-shadow: $text-shadow,
                   (-.5+(random()) * 3) + em
                   (-.5+(random()) * 3) + em
                   6px
                   hsla(random() * 360, 100%, 50%,.9);
    }
    text-shadow: $text-shadow;
  }

  
  .DynamicBackground-outer {
    font: 10vmin/1.3 Serif;
    overflow: hidden;
    background: #123;
  }
  
  .DynamicBackground-inner, .DynamicBackground-innermost {
    display: block;
    font-size: 52px;
    color: transparent;
  }
  
  .DynamicBackground-innermost::before, .DynamicBackground-innermost::after,
  .DynamicBackground-inner::before, .DynamicBackground-inner::after {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 3em;
    height: 3em;
    content: '.';
    mix-blend-mode: screen;
    animation: 44s -27s move infinite ease-in-out alternate;
  }
  
  
  .DynamicBackground-inner::before {
    @include dots($dotsCount);
    animation-duration: 44s;
    animation-delay: -27s;
  }
  
  .DynamicBackground-inner::after {
    @include dots($dotsCount);
    animation-duration: 43s;
    animation-delay: -32s;
  }
  
  .DynamicBackground-innermost::before {
    @include dots($dotsCount);
    animation-duration: 42s;
    animation-delay: -23s;
  }
  
  .DynamicBackground-innermost::after {
    @include dots($dotsCount);
    animation-duration: 41s;
    animation-delay: -19s;
  }
  
  
  
  @keyframes move {
    from {
      transform: rotate(0deg) scale(12) translateX(-20px);
    }
    to {
      transform: rotate(360deg) scale(18) translateX(20px);
    }
  }
  